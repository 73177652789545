import "./App.css";
import Grid from "./components/Grid";
function App() {
  return (
    <div style={{ textAlign: "center" }}>
      <h1>Tic Tac Toe</h1>
      <Grid></Grid>
    </div>
  );
}

export default App;

import { useEffect, useReducer, useState } from "react";
import classes from "./Grid.module.css";
const defaultState = {
  game: [
    { pos: 0, play: "" },{ pos: 1, play: "" },{ pos: 2, play: "" },
    { pos: 3, play: "" },{ pos: 4, play: "" },{ pos: 5, play: "" },
    { pos: 6, play: "" },{ pos: 7, play: "" },{ pos: 8, play: "" },
  ],
  status: false,
  count: 0,
};
const possible_wins = [
  [0, 1, 2],
  [3, 4, 5],
  [6, 7, 8],
  [0, 3, 6],
  [1, 4, 7],
  [2, 5, 8],
  [2, 4, 6],
  [0, 4, 8],
];
function clickReducer(state, action) {
  if (action.type === "click") {
    const count = state.count + 1;
    let currState = [...state.game];
    let status = "";
    const clickElement = currState.find((el) => action.pos === el.pos);
    currState[currState.indexOf(clickElement)] = {
      pos: action.pos,
      play: action.play,
      el: action.el,
    };
    const curr = currState.filter((el) => action.play === el.play);
    const curr_pos = curr.map((el) => el.pos).sort();
    if (curr_pos.length >= 3) {
      for (const i of possible_wins) {
        const [w1,w2,w3] = i;
        console.log(curr_pos);
        if (curr_pos.indexOf(w1)>-1 && curr_pos.indexOf(w2)>-1 && curr_pos.indexOf(w3)>-1) {
          for (const j of i) {
            currState[j].el.classList.add(classes["won"]);
          }
          status = action.play;
          break;
        }
      }

      if (!status) {
        let empty = currState.filter((el) => el.play === "");
        if (!empty.length) {
          status = "draw";
        }
      }
    }

    return { game: currState, status, count };
  }
  if (action.type === "reset") {
    let currState = [...state.game];
    for (const i of currState) {
      i.el?.classList.remove(classes["won"]);
    }
    return defaultState;
  }
  return defaultState;
}
export default function Grid() {
  const [playerState, dispatchClick] = useReducer(clickReducer, defaultState);
  const [showRes, setRes] = useState("");
  useEffect(() => {
    let score;
    if (playerState.status === "X") {
      score = <h1>Player 1 Wins!!!</h1>;
    } else if (playerState.status === "O") {
      score = <h1>Player 2 Wins!!!</h1>;
    } else if (playerState.status === "draw") {
      score = <h1>Draw!!!</h1>;
    }
    setRes(score);
  }, [playerState]);
  const clickHandler = (event) => {
    const boxId = +event.target.id;
    if (
      playerState.game[boxId].play ||
      playerState.count > 9 ||
      playerState.status
    ) {
      return;
    }
    const play = playerState.count % 2 === 0 ? "X" : "O";
    dispatchClick({ type: "click", pos: boxId, play, el: event.target });
  };
  const resetHandler = () => {
    dispatchClick({ type: "reset" });
  };
  return (
    <>
      <div className={classes["container"]}>
        <div className={classes["box"]} onClick={clickHandler} id="0">
          {playerState.game[0].play}
        </div>
        <div className={classes["box"]} onClick={clickHandler} id="1">
          {playerState.game[1].play}
        </div>
        <div className={classes["box"]} onClick={clickHandler} id="2">
          {playerState.game[2].play}
        </div>
        <div className={classes["box"]} onClick={clickHandler} id="3">
          {playerState.game[3].play}
        </div>
        <div className={classes["box"]} onClick={clickHandler} id="4">
          {playerState.game[4].play}
        </div>
        <div className={classes["box"]} onClick={clickHandler} id="5">
          {playerState.game[5].play}
        </div>
        <div className={classes["box"]} onClick={clickHandler} id="6">
          {playerState.game[6].play}
        </div>
        <div className={classes["box"]} onClick={clickHandler} id="7">
          {playerState.game[7].play}
        </div>
        <div className={classes["box"]} onClick={clickHandler} id="8">
          {playerState.game[8].play}
        </div>
      </div>
      <button
        type="button"
        className={classes["button"]}
        onClick={resetHandler}
      >
        Reset
      </button>
      {showRes}
    </>
  );
}
